import React, { useEffect, useState } from 'react';
import axiosInstance from "../../axios";
import {toast} from "react-toastify";
import TradeInventory from "./TradeInventory";
import TradeCoinCard from "./TradeCoinCard";
import {useTranslation} from "react-i18next";

function TradeCard({user_info, user_items = [], user_coins, user_approve, can_edit, onAddItem, onRemoveItemFromTrade, onOpenCoinForm}) {
    const { t } = useTranslation();
    return (
        <div>
            <div><span className="mx-2">{user_info.first_name}:</span></div>
            <div className="card bg-secondary mt-2 mb-2">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <div>{t("Approved")}: {user_approve ? t("Yes") : t("No")}</div>
                    <div>

                        {can_edit ?
                            <button onClick={onOpenCoinForm} className="btn btn-primary">
                                🩸 {user_coins}
                            </button>
                            :
                            <p>🩸 {user_coins}</p>}
                    </div>
                </div>
                <div className="card-body">
                    {user_items.map( e => (
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                {t(e.item.base_item.name)}
                            </div>
                            {(can_edit && !user_approve) && (
                                <button className="btn btn-outline-warning border-0" onClick={() => onRemoveItemFromTrade(e)}>
                                    {t("Remove")}
                                </button>
                            )}
                        </div>
                    ))}
                    {
                        (can_edit && !user_approve) ?
                            <button className="btn btn-link border-0 w-100 mt-1" onClick={onAddItem}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 16 16" >
                                    <path d="M10 1H6V6L1 6V10H6V15H10V10H15V6L10 6V1Z"/>
                                </svg>
                            </button>
                            : null
                    }
                </div>
            </div>
        </div>
    );
}

export default TradeCard;
