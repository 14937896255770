import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import axiosInstance from "../../../axios";
import { toast } from "react-toastify";
import InvWindow from "./inv/InvWindow";
import InvItemWindow from "./inv/InvItemWindow";

const Inventory = () => {
    const [items, setItems] = useState([]);  // Original list of items
    const [resources, setResources] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null)
    const [openItemWindow, setOpenItemWindow] = useState(false)

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const response = await axiosInstance.get(`/user/info`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data !== null) {
                    if (response.data.items !== null) {
                        setUserInfo(response.data);
                    }
                }
            } catch (error) {
                console.log(error);
                toast.error("Failed to fetch user info.");
            }
        };

        const getInventory = async () => {
            try {
                const response = await axiosInstance.get(`/user/inv`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data !== null) {
                    if (response.data.items !== null) {
                        setItems(response.data.items);
                    }
                    if (response.data.resources != null) {
                        setResources(response.data.resources);
                    }
                }
            } catch (error) {
                console.log(error);
                toast.error("Unable to get information about inventory items.");
            }
        };

        getUserInfo();
        getInventory();
    }, []);

    const onSelectItem = (userItem) =>{
        setSelectedItem(userItem)
        setOpenItemWindow(true)
    }
    const onCloseItemWindow = () =>{
        setOpenItemWindow(false)
        setSelectedItem(null)
    }

    const onToggleActiveStatus =  async (item) => {
        try {
            const response = await axiosInstance.post(`/user/inv/item/${item.item.id}/active`,
                {
                    active: !item.is_active
                },{
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data !== null) {
                const updatedItem = {
                    ...item,
                    is_active: !item.is_active
                };

                const updatedItems = items.map(i =>
                    i === item ? updatedItem : i
                );

                setItems(updatedItems);

                if (selectedItem === item) {
                    setSelectedItem(updatedItem);
                }
            }
        } catch (error) {
            console.log(error);
            toast.error("Unable to change item active status.");
        }

    };

    return ( items && userInfo &&
        <>
            <InvWindow
                items={items}
                resources={resources}
                userInfo={userInfo}
                onSelectItem={onSelectItem}
            />
            <InvItemWindow
                item={selectedItem}
                isVisible={openItemWindow}
                onClose={onCloseItemWindow}
                onToggleActiveStatus={onToggleActiveStatus}
            />
        </>

    );
};

export default Inventory;
