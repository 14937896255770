import React, { useState } from 'react';
import {useTranslation} from "react-i18next";

function TradeCoinCard({ userWallet, visible, onAddCoins, onClose }) {
    const {t} = useTranslation()
    const [coinAmount, setCoinAmount] = useState(0);

    const handleSliderChange = (event) => {
        const amount = parseInt(event.target.value, 10);
        setCoinAmount(amount);
    };

    const handleAddCoins = () => {
        onAddCoins(coinAmount);
        onClose();
    };

    return ( visible ?
        <div
            className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1050
            }}
        >
            <div className="position-absolute w-100 h-100" onClick={onClose}></div>
            <div className="card bg-secondary mt-2 mb-2">
                <div className="card-body text-center">
                    <h5 className="card-title">{t("Update the amount")}</h5>
                    <div className="">
                        <p>{t("Available Balance")}: {userWallet.premium_coins} {t("premium_coins")}</p>
                        <p>{t("Amount")}:</p>
                        <input
                            type="number"
                            min="0"
                            max={userWallet.premium_coins}
                            value={coinAmount}
                            onChange={handleSliderChange}
                            className="form-control"
                        />
                    </div>
                    <div>
                        <input
                            type="range"
                            min="0"
                            max={userWallet.premium_coins}
                            value={coinAmount}
                            onChange={handleSliderChange}
                            className="form-range"
                        />
                    </div>
                    <div className="row m-1">
                        <div className="p-1 col-6">
                            <button onClick={onClose} className="btn btn-danger w-100">
                                {t("Close")}
                            </button>
                        </div>
                        <div className="p-1 col-6">
                            <button onClick={handleAddCoins} className="btn btn-primary w-100">
                                {t("Update")}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div> : <></>
    );
}

export default TradeCoinCard;
