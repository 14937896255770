import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

function Halloween() {
    return (
        <>
            <div className="position-absolute w-25 position-fixed top-0 z-1">
                <img className="w-100" src="/spider.gif"/>
            </div>
            <Outlet />
        </>
    );
}

export default Halloween;