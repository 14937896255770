import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
import InvWindow from "../user/customization/inv/InvWindow";

function TradeInventory({ user_inv_items = [], user_inv_resources = [], user_trade_items = [], active, onSelectItem, onClose }) {
    const {t} = useTranslation()

    return (
        active ?
            (
                <div
                    className="position-fixed bg-body top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
                    style={{
                        zIndex: 1050 // High z-index to ensure it overlays other elements
                    }}
                >
                    <InvWindow
                        items={user_inv_items.filter((e)=> !user_trade_items.some(ti => ti.item.id === e.item.id))}
                        resources={user_inv_resources}
                        onClose={onClose}
                        onSelectItem={onSelectItem}
                    />

                </div>
            ) : null

    );
}

export default TradeInventory;
