import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import {useTranslation} from "react-i18next";

const InvItemWindow = ({ item, isVisible, onClose, onToggleActiveStatus }) => {
    const {t} = useTranslation()
    return isVisible && item != null ? (
        <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
             style={{
                 backgroundColor: 'rgba(0, 0, 0, 0.5)',
                 zIndex: 1050
             }}>
            <div className="position-absolute w-100 h-100" onClick={onClose}></div>
            <div className="card">
                <div className="card-body">
                    <div>
                        <div>
                            <div>
                                <small className="hint-text">{t(item.item.base_item.type)}</small>
                            </div>
                            <div className="my-1">
                                <span>{t(item.item.base_item.name)}</span>
                            </div>
                        </div>
                        <div className="my-1">
                            {item.item.base_item.type !== "case" ?
                                <button className={`btn w-100 my-1 ${item.is_active ? "btn-warning" : "btn-primary"}`} onClick={() => onToggleActiveStatus(item)}>
                                    {item.is_active ? t("Disable") : t("Activate")}
                                </button>
                                :
                                <a className="btn btn-primary w-100 my-1" href={`/opencase/${item.item.base_item.name}`}>
                                    {t("Open Case")}
                                </a>
                            }
                            <button onClick={onClose} className="btn btn-danger w-100 my-1">
                                {t("Close")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default InvItemWindow;
