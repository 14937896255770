import React, { useEffect, useState } from 'react';
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import {useTranslation} from "react-i18next";

const BuyItem = ({ isOpen, itemName, onClose }) => {
    const {t} = useTranslation()
    const [itemBuyDetail, setItemBuyDetail] = useState(null); // Use null as initial state
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadItemBuyDetail = async () => {
            try {
                const response = await axiosInstance.get(`/shop/item/${itemName}`, {
                    headers: { 'Content-Type': 'application/json' }
                });
                if (response.data) {
                    setItemBuyDetail(response.data);
                }
            } catch (error) {
                console.log(error);
                toast.error(t("Failed to fetch item data."));
            } finally {
                setLoading(false); // Stop loading after request finishes
            }
        };

        if (isOpen) {
            setLoading(true);
            loadItemBuyDetail();
        }
    }, [isOpen, itemName]);

    const onBuy = async () => {
        if(!itemBuyDetail){
            return
        }
        try {
            const response = await axiosInstance.post(`/shop/buyItem`, itemBuyDetail, {
                headers: { 'Content-Type': 'application/json' }
            });
            if (response.data) {
                toast.success(`${t("You successfully bought")} ${t(itemBuyDetail.base_item?.name)}`);
                onClose();
            }
        } catch (error) {
            console.log(error);
            toast.error("Failed to buy item.");
        }
    };

    return (
        <>
            {isOpen ? (
                <div className="fixed-bottom card opacity-100">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <span>{t("Invoice")}</span>
                        <button className="btn-close btn-close-white ms-auto" onClick={onClose}></button>
                    </div>
                    <div className="card-body">
                        <div className="text-center pb-3">
                            {!loading && itemBuyDetail ? (
                                <>
                                    <div>
                                        <h3>{t(itemName)}</h3>
                                    </div>
                                    <div>
                                        <span>{itemBuyDetail.price_data?.amount} <small>{t(itemBuyDetail.price_data?.payment_type)}</small></span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <h3 className="opacity-0">Loading</h3>
                                    </div>
                                    <div>
                                        <span className="opacity-0">Loading</span>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="w-100">
                            <button className="btn btn-primary w-100" onClick={onBuy}>{t("Buy")}</button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default BuyItem;
